import { SortArrayOfObjectsMultiple } from '@app/utils/Sort'
import { AxiosInstance } from '@griegconnect/krakentools-react-kraken-app'
import { IAssign } from '../dtos/assignDto'
import { IAvailableDto, IIAvailableConsigneeDto, refTypes } from '../dtos/availableDto'
import { IDepotDto } from '../dtos/containerDto'
import { IWorkorderDetailsDto, IWorkorderDetailsResponse } from '../dtos/workorderDetailsDto'
import { IWorkorderForm, IWorkorderPreadviceForm } from '../dtos/workorderDto'
import * as Page from '../Page'
import { HaulierWorkorderSearch } from '@app/modules/hauliers/Workorders'
import qs from 'qs'
import { HaulierId } from '../dtos/terminalHaulierAgreement'

export class HaulierWorkorderApi {
  private httpClient: AxiosInstance
  haulierid: HaulierId

  constructor(httpClient: any, apiUrl: string, haulierid: HaulierId, useAuth: boolean = true) {
    this.httpClient = httpClient(apiUrl, useAuth)
    this.haulierid = haulierid
  }

  // TODO: move into HaulierTerminalCtsApi.ts
  async availableUnknownedRef(terminalRef: string, ref: string): Promise<IAvailableSearch | undefined> {
    try {
      const response = await this.httpClient.get<IAvailableDto>(
        `/hauliers/${this.haulierid}/terminals/${terminalRef}/available?q=${ref}`
      )
      if (response.status === 200) {
        const delivery = response.data.delivery
        const pickup = response.data.pickup
        if (delivery.length > 0 || pickup.identified.length > 0 || pickup.unidentified.length > 0) {
          return {
            booking: response.data,
            type: response.data.type,
          }
        }
      }
      return undefined
    } catch (error) {
      // this.logger.error(`Can't get available booking/container refs`, error);
    }
    return undefined
  }
  // TODO: move into HaulierTerminalCtsApi.ts
  async availableContainerRef(terminalRef: string, ref: string): Promise<IAvailableSearch | undefined> {
    const response = await this.httpClient.get<IAvailableDto>(
      `/hauliers/${this.haulierid}/terminals/${terminalRef}/available?q=${ref}`
    )
    if (response.status === 200) {
      const delivery = response.data.delivery
      const pickup = response.data.pickup
      if (delivery.length > 0 || pickup.identified.length > 0 || pickup.unidentified.length > 0) {
        return {
          booking: response.data,
          type: 'container',
        }
      }
    }
    return undefined
  }
  // TODO: move into HaulierTerminalCtsApi.ts
  async availableBookingRef(terminalRef: string, ref: string): Promise<IAvailableSearch | undefined> {
    const response = await this.httpClient.get<IAvailableDto>(
      `/hauliers/${this.haulierid}/terminals/${terminalRef}/available?q=${ref}`
    )
    if (response.status === 200) {
      const delivery = response.data.delivery
      const pickup = response.data.pickup
      if (delivery.length > 0 || pickup.identified.length > 0 || pickup.unidentified.length > 0) {
        return {
          booking: response.data,
          type: 'booking',
        }
      }
    }
    return undefined
  }
  // TODO: move into HaulierTerminalCtsApi.ts
  async availableConsigneeRef(
    terminalRef: string,
    consigneeId: string
  ): Promise<IAvailableSearchConsignee | undefined> {
    const response = await this.httpClient.get<IIAvailableConsigneeDto>(
      `/hauliers/${this.haulierid}/terminals/${terminalRef}/available?q=${consigneeId}`
    )
    if (response.status === 200) {
      const delivery = response.data.delivery
      const pickup = response.data.pickup
      if (delivery.length > 0 || pickup.identified.length > 0 || pickup.unidentified.length > 0) {
        return {
          booking: response.data,
          status: 'consignee',
        }
      }
    }
    return undefined
  }
  // TODO: move into HaulierTerminalCtsApi.ts
  async owners(terminalRef: string) {
    const response = await this.httpClient.get<{ owners: IDepotDto[] }>(
      `/hauliers/${this.haulierid}/terminals/${terminalRef}/owners`
    )
    return response.data.owners
  }
  // TODO: move into HaulierTerminalCtsApi.ts
  async create(workorder: IWorkorderForm) {
    await this.httpClient.post(`/hauliers/${this.haulierid}/workorders`, workorder)
  }

  async createFromPreadvice(workorder: IWorkorderPreadviceForm) {
    await this.httpClient.post(`/hauliers/${this.haulierid}/workorders/preadvices`, workorder)
  }

  async check(workOrderId: string, data: any) {
    const resp = await this.httpClient.post<IWorkorderValidation>(
      `/hauliers/${this.haulierid}/workorders/${workOrderId}/check`,
      data
    )
    return resp.data
  }

  async getWorkorders(pageParams: Page.Search, searchParams: HaulierWorkorderSearch) {
    const response = await this.httpClient.get<IWorkorderDetailsResponse>(`/hauliers/${this.haulierid}/workorders`, {
      params: { ...pageParams, ...searchParams },
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    })

    response.data.values.sort(SortArrayOfObjectsMultiple('workorder.terminal.name', 'workorder.created.timestamp'))
    return response.data
  }

  async getWorkorderDetails(id: string) {
    const response = await this.httpClient.get<IWorkorderDetailsDto>(`/hauliers/${this.haulierid}/workorders/${id}`)
    return response.data
  }

  async delete(id: string) {
    const response = await this.httpClient.delete(`/hauliers/${this.haulierid}/workorders/${id}`)
    return response.data
  }

  async assign(workOrderId: string, data: IAssign) {
    const payload: IAssign = {
      ...data,
    }
    if (payload.driver === '') {
      payload.driver = null
    }
    if (payload.vehicle === '') {
      payload.vehicle = null
    }
    await this.httpClient.post(`/hauliers/${this.haulierid}/workorders/${workOrderId}/assign`, payload)
  }

  async gateStatus(woid: string) {
    const response = await this.httpClient.get<IGateStatus>(
      `/hauliers/${this.haulierid}/workorders/${woid}/gate-status`
    )
    return response.data
  }
}

export interface IGateStatus {
  gate_in: {
    completed?: string
    message?: string
    passing?: string
  } | null
  gate_out: {
    completed?: string
    message?: string
    passing?: string
  } | null
}

export interface IWorkorderValidation {
  submission: IWorkorderValidationSubmission
  workorder: IWorkorderDetailsDto
}

export interface IWorkorderValidationSubmission {
  pickup: {
    identified: IWorkorderValidationSubmissionError[]
    unidentified: IWorkorderValidationSubmissionError[]
  }
  delivery: IWorkorderValidationSubmissionError[]
}

interface IWorkorderValidationSubmissionError {
  errors: string[]
  id: string
  metadata: any
  number: string
  owners: string[]
}

// TODO: We should remove the use of IBookingAvailable and only use IAvailableDto
export interface IAvailableSearch {
  type: refTypes
  booking: IAvailableDto
}

export interface IAvailableSearchConsignee {
  status: refTypes
  booking: IIAvailableConsigneeDto
}
