export enum TerminalRoles {
  GateKeeper = 'GATEKEEPER',
}

export enum HaulierRoles {
  Driver = 'DRIVER',
  Admin = 'HAULIER_ADMIN',
}

export enum GodRoles {
  God = 'GOD_MODE',
  Support = 'SUPPORT',
}
