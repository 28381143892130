import * as React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgDriverPerson = (props: SvgIconProps) =>
  React.createElement(
    SvgIcon,
    props,
    <path
      fillRule="evenodd"
      d="M12 4C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4Zm0 2c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2ZM11.971 13h.058c.978 0 1.954 0 2.844.082.899.084 1.79.256 2.574.649a4.147 4.147 0 0 1 1.92 1.94c.434.886.633 1.99.633 3.329a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1c0-1.34.2-2.443.633-3.33a4.148 4.148 0 0 1 1.92-1.94c.784-.392 1.675-.564 2.574-.648C10.017 13 10.993 13 11.97 13Zm5.978 5c-.07-.629-.206-1.098-.379-1.451a2.151 2.151 0 0 0-1.017-1.03 3.025 3.025 0 0 0-.167-.076L13.828 18h4.121ZM11 18H6.05c.07-.629.207-1.098.38-1.451a2.15 2.15 0 0 1 1.017-1.03c.466-.232 1.075-.372 1.864-.445C10.1 15 10.988 15 12 15c.717 0 1.372 0 1.973.027L11 18Z"
      clipRule="evenodd"
    />
  )
export default SvgDriverPerson
