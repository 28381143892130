import { AxiosInstance } from '@griegconnect/krakentools-react-kraken-app'
import { SortArrayOfObjects } from '@app/utils/Sort'
import { ITerminalResponse } from './dtos/terminalDto'

export class TerminalsApi {
  private httpClient: AxiosInstance

  constructor(httpClient: any, apiUrl: string) {
    this.httpClient = httpClient(apiUrl, true)
  }

  async terminals() {
    const terminalsData = await this.httpClient.get<ITerminalResponse>(`/terminals`)
    return terminalsData.data.terminals.sort(SortArrayOfObjects('company.name'))
  }

  // TODO: Add create terminal
}
