/* eslint-disable */
/**
 * Example: this.myArray.sort(SortArrayOfObjectsMultiple('firstname', '-surname'));
 *
 * @export
 * @returns
 */
export function SortArrayOfObjectsMultiple(...arg: string[]) {
	/*
	 * save the arguments object as it will be overwritten
	 * note that arguments object is an array-like object
	 * consisting of the names of the properties to sort by
	 */
	const props = arguments;
	return (obj1: any, obj2: any) => {
		const numberOfProperties = props.length;
		let i = 0;
		let result = 0;
		/* try getting a different result from 0 (equal)
		 * as long as we have extra properties to compare
		 */
		while(result === 0 && i < numberOfProperties) {
			result = SortArrayOfObjects(props[i])(obj1, obj2);
			i++;
		}
		return result;
	};
}

/**
 * Example: this.myArray.sort(SortArrayOfObjects('name'));
 *
 * @export
 * @param {string} propertyPath
 * @returns
 */
export function SortArrayOfObjects(propertyPath: string) {
	let sortOrder = 1;
	if(propertyPath[0] === '-') {
		sortOrder = -1;
		propertyPath = propertyPath.substr(1);
	}
	return (a: {},b: {}) => {
		/* next line works with strings, numbers,
		 * and you may want to customize it to your needs
		 */
		const result = (getPropertyValue(a, propertyPath) < getPropertyValue(b, propertyPath)) ? -1 : (getPropertyValue(a, propertyPath) > getPropertyValue(b, propertyPath)) ? 1 : 0;
		return result * sortOrder;
	};
}

function getPropertyValue(obj: any, propertyPath: string, defaultValue = Infinity) {
	try {
		return propertyPath.split('.').reduce(function(obj, p) {
			return obj[p];
		}, obj);
	} catch(e) {
		return defaultValue
	}
}
