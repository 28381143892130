import { AxiosInstance } from '@griegconnect/krakentools-react-kraken-app'
import { IConsigneeResponse } from './dtos/consigneeDto'
import { HaulierId } from './dtos/terminalHaulierAgreement'
import { IContainerForm, IContainerSizeDto, IContainerTypeDto, IContainerOwnerDto } from './dtos/containerDto'
import { BookingType } from './types/Booking'

interface ICommoditiesResponse {
  commodities: ICommodityDto[]
}
export interface ICommodityDto {
  id: string
  name: string
}
interface IImosResponse {
  imos: IImoDto[]
}
export interface IImoDto {
  id: string
  name: string
  description: string
}

interface IContainerOwnerResponse {
  owners: IContainerOwnerDto[]
}

export class HaulierTerminalCtsApi {
  private httpClient: AxiosInstance

  constructor(httpClient: any, apiUrl: string, haulierId: HaulierId, useAuth: boolean = true) {
    this.httpClient = httpClient(`${apiUrl}/hauliers/${haulierId}/terminals`, useAuth)
  }

  async consignees(terminalRef: string) {
    try {
      const response = await this.httpClient.get<IConsigneeResponse>(`${terminalRef}/consignees`)
      return response.data.consignees
    } catch (error) {
      return []
    }
  }

  async commodities(terminalRef: string) {
    const response = await this.httpClient.get<ICommoditiesResponse>(`${terminalRef}/commodities`)
    return response.data.commodities
  }

  async imos(terminalRef: string) {
    const response = await this.httpClient.get<IImosResponse>(`${terminalRef}/imos`)
    return response.data.imos
  }

  async containerSizes(terminalRef: string) {
    const response = await this.httpClient.get<IContainerSizeDto[]>(`${terminalRef}/container-sizes`)
    return response.data
  }

  async containerTypes(terminalRef: string) {
    const response = await this.httpClient.get<IContainerTypeDto[]>(`${terminalRef}/container-types`)
    return response.data
  }

  async containerOwners(terminalRef: string) {
    const response = await this.httpClient.get<IContainerOwnerResponse>(`${terminalRef}/owners`)
    return response.data
  }

  async bookingSearch(terminalRef: string, search: string, abortSignal?: AbortSignal) {
    const response = await this.httpClient.get<BookingType.Data>(`${terminalRef}/bookings?ref=${search}`, {
      signal: abortSignal,
    })
    return response.data
  }

  async createContainer(terminalRef: string, data: IContainerForm) {
    const response = await this.httpClient.post<string>(`${terminalRef}/containers`, data)
    return response.data
  }
}
