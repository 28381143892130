import { useHttp } from '@griegconnect/krakentools-react-kraken-app'

import { useMemo } from 'react'
export const useHttpClient = () => {
  const { httpClient } = useHttp()
  const getHttpClient = useMemo(() => httpClient, [httpClient])
  return {
    httpClient: getHttpClient,
  }
}
