export interface ResolvedCompany {
	resolved: true
	id: string
	name: string
	ref: string
}

export interface UnresolvedCompany {
	resolved: false
	id: string
}

export type ICompanyDto = ResolvedCompany | UnresolvedCompany

export const toResolved = (company: ICompanyDto): ResolvedCompany =>
	company.resolved
		? company
		: {
			resolved: true,
			id: company.id,
			name: 'Unknown',
			ref: ''
		};
