import React from 'react'
import { Settings, VehicleTruck, HostCompany, GateIn } from '@griegconnect/krakentools-react-icons'
import { Navigation, requiresOneOfPermissions } from '@griegconnect/krakentools-react-kraken-app'
import { tenantRouterPath } from '@app/utils/navigation'
import { accessTerminalGateKeeper, accessSupport } from '@app/common/access'
import { Trans } from 'react-i18next'
import { NavAccessDeps, PATH_SETTINGS_BASE, PATH_SETTINGS_GATE_BASE } from '@app/constants'
import { hasFeature } from '@app/utils/access'

const Hauliers = React.lazy(() => import('@app/modules/terminals/companies/TerminalCompanies'))
const Vehicles = React.lazy(() => import('@app/common/components/Vehicles'))
const ImportLog = React.lazy(() => import('@app/modules/terminals/ImportLog'))
const DraftWorkorders = React.lazy(() => import('@app/modules/terminals/DraftWorkorders'))
const TerminalGates = React.lazy(() => import('@app/modules/terminals/TerminalGates'))
const AddGate = React.lazy(() => import('@app/modules/terminals/AddGatePage'))
const EditGate = React.lazy(() => import('@app/modules/terminals/EditGatePage'))
const Schedules = React.lazy(() => import('@app/modules/terminals/settings/Schedules'))

export const terminalNavs: Navigation.Item<NavAccessDeps>[] = [
  {
    path: tenantRouterPath('/hauliers'),
    name: <Trans i18nKey="navigation.terminal.hauliers" />,
    page: <Hauliers />,
    access: (d) => requiresOneOfPermissions([accessTerminalGateKeeper])(d),
    menu: {
      icon: <HostCompany />,
    },
  },
  {
    path: tenantRouterPath('/vehicles'),
    name: <Trans i18nKey="navigation.terminal.vehicles" />,
    page: <Vehicles />,
    access: (d) => requiresOneOfPermissions([accessTerminalGateKeeper])(d),
    menu: {
      icon: <VehicleTruck />,
    },
  },
  // {
  //   path: tenantRouterPath('/workorder-details/:woid'),
  //   name: 'Workorder Details',
  //   page: React.lazy(() => import('@app/modules/terminals/WorkorderDetails')),
  //   access: (d) => requiresOneOfPermissions([accessTerminalGateKeeper])(d),
  // },
  {
    path: tenantRouterPath('/import-log'),
    name: <Trans i18nKey="navigation.terminal.importLog" />,
    page: <ImportLog />,
    access: (d) => requiresOneOfPermissions([accessTerminalGateKeeper])(d),
    menu: {
      icon: <GateIn />,
    },
  },
  {
    path: tenantRouterPath('/draft-workorders'),
    name: <Trans i18nKey="navigation.terminal.draftWorkorders" />,
    page: <DraftWorkorders />,
    access: (d) => requiresOneOfPermissions([accessSupport])(d),
  },
  {
    path: tenantRouterPath('/settings'),
    name: <Trans i18nKey="navigation.terminal.settings" />,
    access: (d) =>
      requiresOneOfPermissions([accessSupport])(d) ||
      (requiresOneOfPermissions([accessTerminalGateKeeper])(d) && hasFeature('preadvice')(d)),
    menu: {
      icon: <Settings />,
    },
    subNavs: [
      {
        path: PATH_SETTINGS_BASE + '/schedules',
        name: <Trans i18nKey="navigation.terminal.schedules" />,
        page: <Schedules />,
        access: (d) =>
          requiresOneOfPermissions([accessTerminalGateKeeper, accessSupport])(d) && hasFeature('preadvice')(d),
        menu: true,
      },
      {
        path: PATH_SETTINGS_GATE_BASE,
        name: <Trans i18nKey="navigation.terminal.gates" />,
        page: <TerminalGates />,
        access: (d) => requiresOneOfPermissions([accessSupport])(d),
        menu: true,
        subNavs: [
          {
            path: PATH_SETTINGS_GATE_BASE + '/add',
            name: <Trans i18nKey="navigation.terminal.addGates" />,
            page: <AddGate />,
            access: (d) => requiresOneOfPermissions([accessSupport])(d),
          },
          {
            path: PATH_SETTINGS_GATE_BASE + '/:gateId',
            name: <Trans i18nKey="navigation.terminal.editGate" />,
            page: <EditGate />,
            access: (d) => requiresOneOfPermissions([accessSupport])(d),
          },
        ],
      },
    ],
  },
]
