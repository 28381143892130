import * as Page from './../Page'
import { SortArrayOfObjectsMultiple } from '@app/utils/Sort'
import { AxiosInstance } from '@griegconnect/krakentools-react-kraken-app'
import { IAssign } from '../dtos/assignDto'
import { IAvailableDto, IIAvailableConsigneeDto, refTypes } from '../dtos/availableDto'
import { IWorkorderDetailsDto, IWorkorderDetailsResponse } from '../dtos/workorderDetailsDto'
import { TerminalWorkorderSearch } from '@app/modules/terminals/Workorders'
import qs from 'qs'

export class TerminalWorkorderApi {
  private httpClient: AxiosInstance
  terminalRef: string | null

  constructor(httpClient: any, apiUrl: string, terminalRef: string, useAuth: boolean = true) {
    this.terminalRef = terminalRef ? terminalRef : null
    this.httpClient = httpClient(apiUrl, useAuth)
  }

  async getWorkorderDetails(id: string) {
    const response = await this.httpClient.get<IWorkorderDetailsDto>(`/terminals/${this.terminalRef}/workorders/${id}`)
    return response.data
  }
  async getTerminalWorkorders(page: Page.Search, searchParams: TerminalWorkorderSearch = {}) {
    const response = await this.httpClient.get<IWorkorderDetailsResponse>(`/terminals/${this.terminalRef}/workorders`, {
      params: { ...page, ...searchParams },
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    })
    response.data.values.sort(SortArrayOfObjectsMultiple('workorder.terminal.name', 'workorder.created.timestamp'))
    return response.data
  }

  async check(workOrderId: string, data: any) {
    const resp = await this.httpClient.post<IWorkorderValidation>(
      `/terminals/${this.terminalRef}/workorders/${workOrderId}/check`,
      data
    )
    return resp.data
  }

  async delete(id: string) {
    const response = await this.httpClient.delete(`/terminals/${this.terminalRef}/workorders/${id}`)
    return response.data
  }

  async assign(workOrderId: string, data: IAssign) {
    const postData: IAssign = {
      ...data,
    }
    if (postData.driver === '') {
      postData.driver = null
    }
    if (postData.vehicle === '') {
      postData.vehicle = null
    }
    await this.httpClient.post(`/terminals/${this.terminalRef}/workorders/${workOrderId}/assign`, postData)
  }

  async finalize(workorderId: string) {
    await this.httpClient.post(`/terminals/${this.terminalRef}/workorders/${workorderId}/finalize`)
  }

  async complete(workorderId: string) {
    await this.httpClient.post(`/terminals/${this.terminalRef}/workorders/${workorderId}/complete`)
  }
}

export interface IWorkorderValidation {
  submission: IWorkorderValidationSubmission
  workorder: IWorkorderDetailsDto
}

export interface IWorkorderValidationSubmission {
  pickup: {
    identified: IWorkorderValidationSubmissionError[]
    unidentified: IWorkorderValidationSubmissionError[]
  }
  delivery: IWorkorderValidationSubmissionError[]
}

interface IWorkorderValidationSubmissionError {
  errors: string[]
  id: string
  metadata: any
  number: string
  owners: string[]
}

// TODO: We should remove the use of IBookingAvailable and only use IAvailableDto
export interface IBookingAvailable {
  type: refTypes
  booking: IAvailableDto
}

export interface IAvailableConsigneeBooking {
  status: refTypes
  booking: IIAvailableConsigneeDto
}
export interface WorkorderFilter {
  deleted?: boolean
  completed?: boolean
  approved?: boolean
}

export interface WorkorderStatus {
  status?: 'deleted' | 'completed' | 'approved'
}
