import { AxiosInstance } from '@griegconnect/krakentools-react-kraken-app'
import { TimeSlotType } from '../types/TimeSlot'

export class TerminalTimeSlotApi {
  private httpClient: AxiosInstance

  constructor(httpClient: any, apiBaseUrl: string, ref: string) {
    this.httpClient = httpClient(`${apiBaseUrl}/terminals/${ref}/time-slots`, true)
  }

  async timeSlots() {
    const response = await this.httpClient.get<TimeSlotType.Data[]>(``)
    return response.data
  }

  async create(form: Omit<TimeSlotType.Form, 'id'>) {
    const response = await this.httpClient.post<string, string, Omit<TimeSlotType.Form, 'id'>>('', form)
    return response
  }

  async update(form: TimeSlotType.Form) {
    const response = await this.httpClient.put<string, string, TimeSlotType.Form>(form.id, form)
    return response
  }

  async delete(id: TimeSlotType.Data['id']) {
    await this.httpClient.delete<string, string, TimeSlotType.Form>(id)
  }
}
