import { Tenant } from '@griegconnect/krakentools-react-kraken-app'
import { GodRoles, HaulierRoles, TerminalRoles } from '../lib/types'

export interface AccessDependencies {
  activeTenant: Tenant.Type
}

// Fake permissions
export const accessHaulierDriver: Tenant.Permission = { domain: 'haulier', name: HaulierRoles.Driver }
export const accessHaulierAdmin: Tenant.Permission = { domain: 'haulier', name: HaulierRoles.Admin }
export const accessTerminalGateKeeper: Tenant.Permission = { domain: 'terminal', name: TerminalRoles.GateKeeper }
export const accessSupport: Tenant.Permission = { domain: 'terminal', name: GodRoles.Support }

// Real permissions
export const permissionWorkorderAssign: Tenant.Permission = { domain: 'haulier', name: 'workorder:assign' }
export const permissionWorkorderCreate: Tenant.Permission = { domain: 'haulier', name: 'workorder:create' }
export const permissionAgreementCreate: Tenant.Permission = { domain: 'haulier', name: 'agreement:create' }
export const permissionUsersDelete: Tenant.Permission = { domain: 'company', name: 'users:delete' }
