import React from 'react'
import { BookingReference, PersonDriver, TimeCalendar } from '@griegconnect/krakentools-react-icons'
import {
  Navigation,
  requiresOneOfPermissions,
  requiresAllOfPermissions,
  ConfigType,
} from '@griegconnect/krakentools-react-kraken-app'
import { tenantRouterPath } from '@app/utils/navigation'
import { accessHaulierAdmin, accessHaulierDriver } from '@app/common/access'
import { Trans } from 'react-i18next'
import { NavAccessDeps } from '@app/constants'
import { hasFeature } from '@app/utils/access'

export const PATH_HAULIER_PRE_ADVICES = tenantRouterPath('/pre-advices')
export const PATH_CREATE_WORKORDER = tenantRouterPath('/create-workorder')
export const PATH_HAULIER_ADMIN_MY_WORKORDERS = tenantRouterPath('/my-workorders')

const PreAdvices = React.lazy(() => import('@app/modules/hauliers/PreAdvices/PreAdvices'))
const Drivers = React.lazy(() => import('@app/modules/hauliers/Drivers'))
const MyWorkorders = React.lazy(() => import('../drivers/MyWorkorders'))
const FindReferences = React.lazy(() => import('./FindReferences'))
const CreateWorkorder = React.lazy(() => import('./CreateWorkorder'))
const WorkorderDetails = React.lazy(() => import('@app/modules/hauliers/WorkorderDetails'))

export function haulierNavs(environment: ConfigType['environment']): Navigation.Item<NavAccessDeps>[] {
  const navs: Navigation.Item<NavAccessDeps>[] = [
    {
      path: PATH_HAULIER_PRE_ADVICES,
      name: 'Pre-advices',
      page: <PreAdvices />,
      access: (d) => requiresOneOfPermissions([accessHaulierAdmin])(d) && hasFeature('preadvice')(d),
      menu: {
        icon: <TimeCalendar />,
      },
    },
    {
      path: tenantRouterPath('/drivers'),
      name: <Trans i18nKey="navigation.haulier.drivers" />,
      page: <Drivers />,
      access: (d) => requiresOneOfPermissions([accessHaulierAdmin])(d),
      menu: {
        icon: <PersonDriver />,
      },
    },
    {
      path: PATH_HAULIER_ADMIN_MY_WORKORDERS,
      name: <Trans i18nKey="navigation.haulier.myWorkorders" />,

      page: <MyWorkorders />,
      access: (d) => requiresAllOfPermissions([accessHaulierAdmin])(d),
      menu: {
        icon: <BookingReference />,
      },
    },
    {
      path: PATH_CREATE_WORKORDER,
      name: <Trans i18nKey="navigation.haulier.createWorkorder" />,
      page: <FindReferences />,
      access: (d) => requiresOneOfPermissions([accessHaulierAdmin, accessHaulierDriver])(d),
      subNavs: [
        {
          path: PATH_CREATE_WORKORDER + '/:terminalRef/create',
          name: <Trans i18nKey="navigation.haulier.createWorkorder" />,
          page: <CreateWorkorder />,
          access: (d) => requiresOneOfPermissions([accessHaulierAdmin, accessHaulierDriver])(d),
        },
      ],
    },
    {
      path: tenantRouterPath('/workorder-details/:woid'),
      name: <Trans i18nKey="navigation.haulier.workorderDetails" />,
      page: <WorkorderDetails />,
      access: (d) => requiresOneOfPermissions([accessHaulierAdmin])(d),
    },
  ]

  return navs
}
