import React, { createContext, useContext, useState } from 'react'
import { ITerminalDetails } from './lib/apis/dtos/terminalDto'
import { HaulierId, IHaulier } from './lib/apis/dtos/terminalHaulierAgreement'

type TenantCompanyType = 'terminal' | 'haulier'

export type HaulierContextValues = {
  terminals: ITerminalDetails[]
  terminalConfig: (idOrRef: string) => ITerminalDetails
  setTerminals: React.Dispatch<React.SetStateAction<ITerminalDetails[]>>
  hauliers: IHaulier[]
  setHauliers: React.Dispatch<React.SetStateAction<IHaulier[]>>
  resolveHaulierId: (tenantId: string) => HaulierId
  tenantCompanyType: (tenantId: string) => TenantCompanyType
}

export const HaulierContext = createContext<HaulierContextValues | null>(null)
export const useHaulierContext = () => useContext(HaulierContext)!

const HaulierProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [terminals, setTerminals] = useState<ITerminalDetails[]>([])
  const [hauliers, setHauliers] = useState<IHaulier[]>([])
  const terminalConfig = (idOrRef: string) =>
    terminals.find((t) => t.terminal.id === idOrRef || t.terminal.company.ref === idOrRef)!
  const resolveHaulierId = (tenantId: string): HaulierId => {
    const matched = hauliers.find(
      // Info: haulier-id- is added as a workaround to support same tenant as both terminal and haulier
      (haulier) => haulier.managed === null && haulier.company.id === tenantId.replace('haulier-id-', '')
    )
    return matched ? matched.id : ('' as HaulierId)
  }
  const tenantCompanyType = (tenantId: string): TenantCompanyType => {
    return terminals.some((terminal) => terminal.terminal.id === tenantId) ? 'terminal' : 'haulier'
  }

  return (
    <HaulierContext.Provider
      value={{
        terminals,
        terminalConfig,
        setTerminals,
        hauliers,
        setHauliers,
        resolveHaulierId,
        tenantCompanyType,
      }}
    >
      {children}
    </HaulierContext.Provider>
  )
}

export default HaulierProvider
