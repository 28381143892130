import { useConfig, useTenants } from '@griegconnect/krakentools-react-kraken-app'
import { useHttpClient } from '@app/common/hooks/useHttpClient'
import React, { useContext, useEffect, useState } from 'react'
import { useHaulierContext } from '@app/HaulierProvider'
import { HaulierPreAdviceApi } from './apis/hauliers/HaulierPreAdviceApi'
import { HaulierTerminalGatesApi } from './apis/hauliers/HaulierTerminalGatesApi'
import { HaulierTerminalsApi } from './apis/hauliers/HaulierTerminalsApi'
import { HaulierWorkorderApi } from './apis/hauliers/HaulierWorkorderApi'
import { HauliersApi } from './apis/HauliersApi'
import { HaulierTerminalCtsApi } from './apis/HaulierTerminalCtsApi'
import { TerminalScheduleApi } from './apis/terminals/TerminalScheduleApi'
import { TerminalTimeSlotApi } from './apis/terminals/TerminalTimeSlotApi'
import { TerminalsApi } from './apis/TerminalsApi'
import { HaulierTerminalScheduleApi } from './apis/hauliers/HaulierTerminalScheduleApi'
import { TenantApi } from '@griegconnect/krakentools-react-tenants'
import { TerminalWorkorderApi } from './apis/terminals/TerminalWorkorderApi'

export type Apis = {
  hauliersApi: HauliersApi
  haulierPreAdviceApi: HaulierPreAdviceApi
  haulierTerminalCtsApi: HaulierTerminalCtsApi
  haulierTerminalGatesApi: HaulierTerminalGatesApi
  haulierTerminalsApi: HaulierTerminalsApi
  haulierWorkorderApi: HaulierWorkorderApi
  haulierTerminalScheduleApi: HaulierTerminalScheduleApi
  terminalsApi: TerminalsApi
  terminalWorkorderApi: TerminalWorkorderApi
  terminalScheduleApi: TerminalScheduleApi
  terminalTimeSlotApi: TerminalTimeSlotApi
  tenantsApi: TenantApi
}

type ApisContextValues = Apis

export const ApisContext = React.createContext<ApisContextValues | null>(null)
export const useApis = () => useContext(ApisContext)!

export const ApiProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [apis, setApis] = useState<Apis>()
  const config = useConfig()
  const { activeTenant } = useTenants()
  const { httpClient } = useHttpClient()
  const { resolveHaulierId } = useHaulierContext()

  useEffect(() => {
    if (activeTenant) {
      const getApis = (): Apis => {
        // Haulier Apis
        const haulierApi = new HauliersApi(httpClient, config.api.haulierApiUrl)
        const haulierTerminalGatesApi = new HaulierTerminalGatesApi(
          httpClient,
          config.api.haulierApiUrl,
          resolveHaulierId(activeTenant.id)
        )
        const haulierWorkorderApi = new HaulierWorkorderApi(
          httpClient,
          config.api.haulierApiUrl,
          resolveHaulierId(activeTenant.id)
        )
        const haulierPreAdviceApi = new HaulierPreAdviceApi(
          httpClient,
          config.api.haulierApiUrl,
          resolveHaulierId(activeTenant.id)
        )
        const haulierTerminalCtsApi = new HaulierTerminalCtsApi(
          httpClient,
          config.api.haulierApiUrl,
          resolveHaulierId(activeTenant.id)
        )
        const haulierTerminalsApi = new HaulierTerminalsApi(
          httpClient,
          config.api.haulierApiUrl,
          resolveHaulierId(activeTenant.id)
        )
        const haulierTerminalScheduleApi = new HaulierTerminalScheduleApi(
          httpClient,
          config.api.haulierApiUrl,
          resolveHaulierId(activeTenant.id)
        )
        const tenantsApi = new TenantApi(httpClient, config.api.tenantApiUrl, activeTenant.id)

        // Terminals
        const terminalsApi = new TerminalsApi(httpClient, config.api.haulierApiUrl)
        const terminalScheduleApi = new TerminalScheduleApi(httpClient, config.api.haulierApiUrl, activeTenant.ref)
        const terminalTimeSlotApi = new TerminalTimeSlotApi(httpClient, config.api.haulierApiUrl, activeTenant.ref)
        const terminalWorkorderApi = new TerminalWorkorderApi(httpClient, config.api.haulierApiUrl, activeTenant.ref)

        return {
          hauliersApi: haulierApi,
          haulierTerminalGatesApi,
          haulierWorkorderApi,
          haulierPreAdviceApi,
          haulierTerminalCtsApi,
          haulierTerminalsApi,
          haulierTerminalScheduleApi,
          terminalsApi,
          terminalWorkorderApi,
          terminalScheduleApi,
          terminalTimeSlotApi,
          tenantsApi,
        }
      }

      setApis(getApis())
    }
  }, [activeTenant, activeTenant?.id]) // eslint-disable-line react-hooks/exhaustive-deps

  return <ApisContext.Provider value={apis!}>{children}</ApisContext.Provider>
}

export default ApiProvider
