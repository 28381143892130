import {
  Tenant,
  ITenantAccess,
  ConfigType,
  AxiosInstance,
  requiresOneOfPermissions,
} from '@griegconnect/krakentools-react-kraken-app'
import { PermissionLink } from '@griegconnect/krakentools-react-tenants'
import { ITerminalDetails } from '@app/lib/apis/dtos/terminalDto'
import { IHaulier } from '@app/lib/apis/dtos/terminalHaulierAgreement'
import { HauliersApi } from '@app/lib/apis/HauliersApi'
import { TerminalsApi } from '@app/lib/apis/TerminalsApi'
import {
  accessHaulierAdmin,
  accessHaulierDriver,
  accessSupport,
  accessTerminalGateKeeper,
  permissionAgreementCreate,
  permissionWorkorderAssign,
  permissionWorkorderCreate,
} from './access'

const isHaulierCompany = (tenant: ITenantAccess, hauliers: IHaulier[]) => {
  return hauliers.some((haulier) => haulier.managed === null && haulier.company.id === tenant.tenant.id)
}

const isTerminalCompany = (tenant: ITenantAccess, terminals: ITerminalDetails[]) => {
  return terminals.some((terminal) => terminal.terminal.company.id === tenant.tenant.id)
}

const hasOneOfPermissions = (permissions: PermissionLink[] | undefined, tenantAccess: ITenantAccess): boolean => {
  return (
    tenantAccess.permissions !== undefined &&
    permissions !== undefined &&
    requiresOneOfPermissions(permissions)({
      activeTenant: {
        companies: [],
        id: tenantAccess.tenant.id,
        name: tenantAccess.tenant.name,
        permissions: tenantAccess.permissions,
        ref: tenantAccess.tenant.ref,
      },
    })
  )
}

export async function filterUserAccess(
  access: ITenantAccess[],
  config: ConfigType,
  httpClient: (baseUrl: string, useBearerToken?: boolean) => AxiosInstance
): Promise<{ tenants: Tenant.Type[]; terminals: ITerminalDetails[]; hauliers: IHaulier[] }> {
  try {
    const hauliersApiClient = new HauliersApi(httpClient, config!.api.haulierApiUrl)
    const terminalsApiClient = new TerminalsApi(httpClient, config!.api.haulierApiUrl)
    const tenants: Tenant.Type[] = []

    const [hauliers, terminals] = await Promise.all([hauliersApiClient.all(), terminalsApiClient.terminals()])

    const godOrSupportRole = access.some(
      (tenant) =>
        tenant.permissions?.some((permission) => permission.domain === 'global') && tenant.tenant.ref === 'griegconnect'
    )
    const terminalTenants: Tenant.Type[] = access
      .filter(
        (tenant) => isTerminalCompany(tenant, terminals) && hasOneOfPermissions([permissionAgreementCreate], tenant)
        //  tenant.permissions.some((role) => role.name === TerminalRoles.GateKeeper)
      )
      .map((tenant) => {
        const isTerminalGateKeeper = hasOneOfPermissions([permissionAgreementCreate], tenant)
        const generatedTenant: Tenant.Type = {
          id: tenant.tenant.id,
          ref: tenant.tenant.ref,
          name: tenant.tenant.name,
          // type: 'TERMINAL',
          permissions: isTerminalGateKeeper ? [accessTerminalGateKeeper, ...(tenant.permissions ?? [])] : [],
          companies: [],
        }
        if (godOrSupportRole) {
          generatedTenant.permissions.push(accessSupport)
        }
        return generatedTenant
      })
    const haulierTenants: Tenant.Type[] = access
      .filter((tenant) => {
        const isHaulier = isHaulierCompany(tenant, hauliers)
        const hasPermissions = hasOneOfPermissions([permissionWorkorderCreate], tenant)
        return isHaulier && hasPermissions
      })
      .map((tenant) => {
        const isHaulierAdmin = hasOneOfPermissions([permissionWorkorderAssign], tenant)
        const isHaulierDriver = !isHaulierAdmin && hasOneOfPermissions([permissionWorkorderCreate], tenant)

        const haulierExistsAsTerminal = terminals.some(
          (terminalTenant) => terminalTenant.terminal.company.ref === tenant.tenant.ref
        )

        return {
          // Info: haulier-id- is added as a workaround to support same tenant as both terminal and haulier
          id: (haulierExistsAsTerminal ? 'haulier-id-' : '') + tenant.tenant.id,
          name: `${tenant.tenant.name}${terminalTenants.length > 0 ? ' (haulier)' : ''}`,
          permissions: isHaulierAdmin
            ? [accessHaulierAdmin, ...(tenant.permissions ?? [])]
            : isHaulierDriver
            ? [accessHaulierDriver, ...(tenant.permissions ?? [])]
            : [],
          ref: (haulierExistsAsTerminal ? 'haulier-' : '') + tenant.tenant.ref,
          companies: [],
        }
      })
    tenants.push(...terminalTenants, ...haulierTenants)
    return {
      tenants,
      terminals,
      hauliers,
    }
  } catch (error) {
    console.error(error)
    return {
      tenants: [],
      terminals: [],
      hauliers: [],
    }
  }
}
