import React from 'react'
import { Navigation, requiresOneOfPermissions } from '@griegconnect/krakentools-react-kraken-app'
import { tenantRouterPath } from '@app/utils/navigation'
import { accessHaulierAdmin, accessHaulierDriver } from '@app/common/access'
import { Trans } from 'react-i18next'
import { NavAccessDeps } from '@app/constants'

export const PATH_CREATE_WORKORDER = tenantRouterPath('/create-workorder')
export const PATH_DRIVER_WORKORDER_DETAILS = tenantRouterPath('/my/workorder-details/:woid')

const RedirectShortId = React.lazy(() => import('../../common/RedirectShortIds'))
const GateOut = React.lazy(() => import('./GateOutPage'))
const DriverWorkorderDetails = React.lazy(() => import('./DriverWorkorderDetails'))

export const driverNavs: Navigation.Item<NavAccessDeps>[] = [
  {
    path: '/r/:type/:base58UUID',
    name: <Trans i18nKey="navigation.driver.redirect" />,
    page: <RedirectShortId />,
    // access: (d) => requiresOneOfPermissions([accessHaulierDriver, accessHaulierAdmin])(d),
  },
  {
    path: tenantRouterPath('/open-gate/:gateId/terminal/:terminalRef/workorder/:woid'),
    name: <Trans i18nKey="navigation.driver.openGate" />,
    page: <GateOut />,
    access: (d) => requiresOneOfPermissions([accessHaulierDriver, accessHaulierAdmin])(d),
  },
  {
    path: PATH_DRIVER_WORKORDER_DETAILS,
    name: <Trans i18nKey="navigation.driver.myWorkorderDetails" />,
    page: <DriverWorkorderDetails />,
    access: (d) => requiresOneOfPermissions([accessHaulierDriver, accessHaulierAdmin])(d),
  },
]
