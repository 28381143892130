import { useAuth } from '@griegconnect/krakentools-react-kraken-app'
import LogRocket from 'logrocket'
import React, { useEffect } from 'react'

const version = process.env.NODE_ENV === 'development' ? 'dev' : process.env.REACT_APP_SHORT_SHA!

export const LogRocketWrapper: React.FC<React.PropsWithChildren<unknown>> = ({children}) => {
  const { isAuthenticated, user } = useAuth()

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      LogRocket.init('grieg-connect/haulier', {
        release: version,
      })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development' && isAuthenticated && user) {
      LogRocket.identify(user.sub, {
        name: user.name,
        email: user.email,
      })
    }
  }, [isAuthenticated, user]) // eslint-disable-line react-hooks/exhaustive-deps

  return <>{children}</>
}

export default LogRocketWrapper
