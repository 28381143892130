import React from 'react'
import { Navigation } from '@griegconnect/krakentools-react-kraken-app'
import { NavAccessDeps } from './constants'
/*
Legacy redirects:
  /hauliers/*
  /terminals/*
  /drivers/*
*/

/*
Short ids redirect
  /r/:type/:base58UUID
*/

const Redirect = React.lazy(() => import('./common/RedirectLegacyPaths'))
const RedirectShortId = React.lazy(() => import('./common/RedirectShortIds'))

export const redirectNavs: Navigation.Item<NavAccessDeps>[] = [
  {
    path: '/hauliers/:oldRef',
    name: 'Redirect',
    page: <Redirect />,
    exact: false,
  },
  {
    path: '/terminals/:oldRef',
    name: 'Redirect',
    page: <Redirect />,
    exact: false,
  },
  {
    path: '/drivers/:oldRef',
    name: 'Redirect',
    page: <Redirect />,
    exact: false,
  },
  {
    path: '/r/:type/:base58UUID',
    name: 'Redirect short id',
    page: <RedirectShortId />,
    exact: false,
  },
]
