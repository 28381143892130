import { AxiosInstance } from '@griegconnect/krakentools-react-kraken-app'
import { SortArrayOfObjects } from '@app/utils/Sort'
import { IHaulierTerminalResponse } from '../dtos/terminalDto'
import { HaulierId } from '../dtos/terminalHaulierAgreement'

export class HaulierTerminalsApi {
  private httpClient: AxiosInstance

  constructor(httpClient: any, apiBaseUrl: string, haulierId: HaulierId) {
    this.httpClient = httpClient(`${apiBaseUrl}/hauliers/${haulierId}`)
  }

  async terminals() {
    const terminalsData = await this.httpClient.get<IHaulierTerminalResponse>(`/terminals`)
    return terminalsData.data.terminals.sort(SortArrayOfObjects('name'))
  }
}
