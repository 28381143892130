import { Tenant } from '@griegconnect/krakentools-react-kraken-app'
import { tenantRouterPath } from './utils/navigation'

export type Feature = 'preadvice'
export type NavAccessDeps = { activeTenant: Tenant.Type | null; features: Feature[] }

export const tenantsUrlMatchPathParam:string = 'tenantRef'
export const PATH_TENANT_BASE = `/companies/:${tenantsUrlMatchPathParam}`
export const PATH_SETTINGS_BASE = tenantRouterPath(`/settings`)
export const PATH_SETTINGS_GATE_BASE = tenantRouterPath(`/settings/gates`)

export const QUERY_KEY_OVERRIDES = 'overrides'
export const QUERY_KEY_TEMPLATES = 'templates'

export const DOC_GATES_ADMIN_TARGET = `Visiting target from Security.`
export const DOC_GATES_ADMIN_FACILITY = `Facility from Security.`
export const DOC_GATES_ADMIN_CHECKPOINT_IN = `Checkpoint from Security with direction 'in'.`
export const DOC_GATES_ADMIN_CHECKPOINT_IN_PURPOSE = `Purpose from Security.`
export const DOC_GATES_ADMIN_CHECKPOINT_KIOSK = `Checkpoint from Security with direction 'none'.`
export const DOC_GATES_ADMIN_CHECKPOINT_OUT = `Checkpoint from Security with direction 'out'.`
export const DOC_GATES_ADMIN_DRIVER_OPEN = `Show open gate button in app.`
export const DOC_GATES_ADMIN_REQUIRE_VEHICLE = `All vehicles must be added in vehicle registry.`
export const DOC_GATES_ADMIN_SECURITY_ALLOW = `Should only be active if Security doesn't have any integration.`
export const DOC_GATES_ADMIN_SECURITY_PIN = `Activates enter pin flow in kiosk.`

export const LS_KEY_LAST_SELECTED_VEHICLE = 'LastSelectedVehicleRegno'
