import React from 'react'
import { Navigation, requiresTenant, ConfigType } from '@griegconnect/krakentools-react-kraken-app'
import { terminalNavs } from './terminals/terminalNavs'
import { haulierNavs } from './hauliers/haulierNavs'
import { driverNavs } from './drivers/driverNavs'
import { tenantRouterPath } from '../utils/navigation'
import { BookingReference } from '@griegconnect/krakentools-react-icons'
import { Trans } from 'react-i18next'
import { NavAccessDeps } from '@app/constants'

const Workorders = React.lazy(() => import('./Workorders'))

export function navigations(environment: ConfigType['environment']): Navigation.Item<NavAccessDeps>[] {
  return [
    {
      path: tenantRouterPath(),
      name: <Trans i18nKey="navigation.workorders" />,
      page: <Workorders />,
      exact: true,
      menu: {
        icon: <BookingReference />,
      },
      access: requiresTenant,
    },
    ...terminalNavs,
    ...haulierNavs(environment),
    ...driverNavs,
  ]
}
