import { AxiosInstance } from '@griegconnect/krakentools-react-kraken-app'
import { HaulierId } from '../dtos/terminalHaulierAgreement'
import { ScheduleType } from '../types/Schedule'

export class HaulierTerminalScheduleApi {
	private httpClient: AxiosInstance

	constructor(httpClient: any, apiBaseUrl: string, haulierId: HaulierId) {
		this.httpClient = httpClient(`${apiBaseUrl}/hauliers/${haulierId}/terminals`, true)
	}

	async schedules(terminalRef: string) {
		const response = await this.httpClient.get<ScheduleType.DateData[]>(`/${terminalRef}/schedules`)
		return response.data
	}

	async schedulesByDate(terminalRef: string, params: ScheduleType.ScheduleParams) {
		const response = await this.httpClient.get<ScheduleType.DateData[]>(`/${terminalRef}/schedules`, { params: params})
		return response.data
	}

}
