import * as React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgActivity = (props: SvgIconProps) =>
  React.createElement(
    SvgIcon,
    props,
    <path d="M7 5H5v14h6v2H5c-1.1 0-2-.9-2-2V5c0-1.1.9-2 2-2h4.18C9.6 1.84 10.7 1 12 1c1.3 0 2.4.84 2.82 2H19c1.1 0 2 .9 2 2v5h-2V5h-2v3H7V5Zm6-1c0-.55-.45-1-1-1s-1 .45-1 1 .45 1 1 1 1-.45 1-1Z" />,
    <path d="M20.293 12.208a1 1 0 0 1 1.415 0l.085.085a1 1 0 0 1 0 1.414L15.51 20l-3.801-3.793a1 1 0 0 1-.001-1.415l.086-.086a1 1 0 0 1 1.413-.001L15.51 17l4.783-4.792Z" />
  )
export default SvgActivity
