import { useRecoilValue } from 'recoil'
import { leftDrawerAtom, LeftDrawerAtomType } from '../atoms/leftDrawerAtom'

export const useLeftColumnValues = (): LeftDrawerAtomType => {
  const leftDrawerState = useRecoilValue(leftDrawerAtom)

  return {
    ...leftDrawerState,
  }
}
