import { AxiosInstance } from '@griegconnect/krakentools-react-kraken-app'
import { DateTime } from 'luxon'
import Page from '../Page'
import { ScheduleType } from '../types/Schedule';
import { TimeSlotType } from '../types/TimeSlot'

export class TerminalScheduleApi {
  private httpClient: AxiosInstance

  constructor(httpClient: any, apiBaseUrl: string, ref: string) {
    this.httpClient = httpClient(`${apiBaseUrl}/terminals/${ref}/schedules`, true)
  }

  private sortTimeSlots(timeSlots: TimeSlotType.Data[]) {
    return timeSlots.sort((a, b) => (a.time_from > b.time_from ? 1 : -1))
  }

  async schedules() {
    const response = await this.httpClient.get<ScheduleType.Data[]>(``)
    return response.data
  }

	async schedulesByDate(params: ScheduleType.ScheduleParams) {
		const response = await this.httpClient.get<ScheduleType.Data[]>(``, { params: params})
		return response.data
	}

  async templates() {
    const response = await this.httpClient.get<ScheduleType.TemplateData[]>(`/templates`)
    return response.data
      .sort((a, b) => (a.day_of_week < b.day_of_week ? -1 : 1))
      .map((template) => ({
        ...template,
        time_slots: this.sortTimeSlots(template.time_slots),
      }))
  }

  async overrides() {
    const response = await this.httpClient.get<Page<ScheduleType.OverrideData>>(`/overrides`)
    return response.data.values
      .filter((override) => DateTime.fromISO(override.date).diffNow('day').days >= -1)
      .sort((a, b) => (a.date < b.date ? -1 : 1))
      .map((override) => ({
        ...override,
        time_slots: this.sortTimeSlots(override.time_slots),
      }))
  }

  async template(id: string) {
    const response = await this.httpClient.get<ScheduleType.TemplateData>(`/templates/${id}`)
    return {
      ...response.data,
      time_slots: this.sortTimeSlots(response.data.time_slots),
    }
  }

  async override(id: string) {
    const response = await this.httpClient.get<ScheduleType.OverrideData>(`/overrides/${id}`)
    return response.data
  }

  async createTemplate(form: ScheduleType.TemplateForm) {
    const response = await this.httpClient.post<unknown, string, ScheduleType.TemplateForm>(`/templates`, form)
    return response
  }

  async createOverride(form: ScheduleType.OverrideForm) {
    const response = await this.httpClient.post<unknown, string, ScheduleType.OverrideForm>(`/overrides`, form)
    return response
  }

  async updateOverride(form: ScheduleType.OverrideForm) {
    const response = await this.httpClient.put<unknown, string, ScheduleType.OverrideForm>(
      `/overrides/${form.id}`,
      form
    )
    return response
  }
}
