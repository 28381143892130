import * as React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgContainer = (props: SvgIconProps) =>
  React.createElement(
    SvgIcon,
    props,
    <path
      fillRule="evenodd"
      d="M3 3a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V3Zm2 5v8h1v2H5v2h4V4H5v2h1v2H5Zm6-4v16h2V4h-2Zm4 16v-7h1a1 1 0 1 0 0-2h-1V4h4v2h-1v2h1v8h-1v2h1v2h-4Z"
      clipRule="evenodd"
    />
  )
export default SvgContainer
