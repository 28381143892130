import { SortArrayOfObjects } from '@app/utils/Sort'
import { AxiosInstance } from '@griegconnect/krakentools-react-kraken-app'
import { IAvailableDto, IIAvailableConsigneeDto, refTypes } from './dtos/availableDto'
import { HaulierId, IHaulier } from './dtos/terminalHaulierAgreement'
import { IWorkorderDetailsDto } from './dtos/workorderDetailsDto'

export interface ICreateHaulierData {
  /**
   * terminal id
   *
   * @type {string}
   * @memberof ICreateHaulierData
   */
  company: string
  managed: {
    ref: string
    name: string
  }
  email?: string
}

export class HauliersApi {
  private httpClient: AxiosInstance

  constructor(httpClient: any, apiUrl: string, useAuth: boolean = true) {
    this.httpClient = httpClient(apiUrl, useAuth)
  }

  async all() {
    const response = await this.httpClient.get<{ hauliers: IHaulier[] }>(`/hauliers`)
    return response.data.hauliers.sort(SortArrayOfObjects('managed.name'))
  }

  async create(data: ICreateHaulierData) {
    const createResponse = await this.httpClient.post<{ id: string }>(`/hauliers`, data)
    return createResponse.data.id
  }

  async update(haulierId: HaulierId, data: IUpdateHaulierData) {
    const createResponse = await this.httpClient.put<{ id: string }>(`/hauliers/${haulierId}`, data)
    return createResponse.data.id
  }
}

export interface IWorkorderValidation {
  submission: IWorkorderValidationSubmission
  workorder: IWorkorderDetailsDto
}

export interface IWorkorderValidationSubmission {
  pickup: {
    identified: IWorkorderValidationSubmissionError[]
    unidentified: IWorkorderValidationSubmissionError[]
  }
  delivery: IWorkorderValidationSubmissionError[]
}

interface IWorkorderValidationSubmissionError {
  errors: string[]
  id: string
  metadata: any
  number: string
  owners: string[]
}

// TODO: We should remove the use of IBookingAvailable and only use IAvailableDto
export interface IBookingAvailable {
  type: refTypes
  booking: IAvailableDto
}

export interface IAvailableConsigneeBooking {
  status: refTypes
  booking: IIAvailableConsigneeDto
}

export interface IUpdateHaulierData {
  company: string
  managed?: IManagedHaulier
  email?: string
}

interface IManagedHaulier {
  ref: string
  name: string
}
