import i18n from 'i18next'
import I18NextHttpBackend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

// Must be IANA-specified zones that is available in the Intl API
export const defaultTimeZone: string = 'utc'

// Must be a supported BCP-47 Language code that is available in the Intl API
export const defaultLocale: string = 'en'

export const supportedLocales: string[] = ['en', 'no', 'sv', 'fi']

i18n
  .use(I18NextHttpBackend)
  .use(initReactI18next)
  .init({
    supportedLngs: supportedLocales,
    fallbackLng: (code) => {
      const langs: string[] = []
      if (code === 'nb-NO') {
        langs.push('no')
      }
      if (code === 'sv-SE') {
        langs.push('sv')
      }
      if (code === 'fi-FI') {
        langs.push('fi')
      }
      return [...langs, 'en']
    },
    // debug: true,
    nsSeparator: '.',
    keySeparator: '.',
    ns: [
      'global',
      'drivers',
      'workorders',
      'terminalWorkorders',
      'findReferences',
      'workorderDetails',
      'createWorkorder',
      'fullContainerInMeta',
      'assignmentAndSummary',
      'selectEmptyContainerGroups',
      'containerContents',
      'selectContainers',
      'myWorkorders',
      'gateOutPage',
      'driverWorkorderDetails',
      'terminalCompanies',
      'addGatePage',
      'editGatePage',
      'draftWorkorders',
      'importLog',
      'terminalGates',
      'terminalWorkorderDetails',
      'assignedWorkorderPage',
      'navigation',
      'consigneeContainers',
      'vehicles',
      'preadvices',
      'timeSlots',
    ],
    defaultNS: 'global',
    fallbackNS: [],
    cleanCode: true,
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
      allowMultiLoading: false,
      parse: function (data: string) {
        // Remove namespace (top level item)
        const parsedData = JSON.parse(data)
        const keys = Object.keys(parsedData)
        if (keys.length === 1) {
          return parsedData[keys[0]]
        }
      },
    },
    interpolation: { escapeValue: false },
  })

export default i18n
