import { AxiosInstance } from '@griegconnect/krakentools-react-kraken-app'
import { IWorkorderDetailsDto } from '../dtos/workorderDetailsDto'
import { toResolved } from '../dtos/companyDto'
import { HaulierId } from '../dtos/terminalHaulierAgreement'

export class HaulierTerminalGatesApi {
  private httpClient: AxiosInstance

  constructor(httpClient: any, apiUrl: string, haulierId: HaulierId, useAuth: boolean = true) {
    this.httpClient = httpClient(`${apiUrl}/hauliers/${haulierId}/terminals`, useAuth)
  }

  async getGates(terminalRef: string) {
    const response = await this.httpClient.get<{ gates: IGateConfig[] }>(`/${terminalRef}/gates`)
    return response.data.gates
  }

  async openGateIn(gateId: string, workorder: IWorkorderDetailsDto) {
    const response = await this.httpClient.post(
      `/${toResolved(workorder.workorder.terminal.company).ref}/gates/${gateId}/checkpoint/gate_in`,
      { workorder: workorder.workorder.id }
    )
    return response
  }

  async openGateOut(terminalRef: string, gateId: string, woid: string) {
    const response = await this.httpClient.post(`/${terminalRef}/gates/${gateId}/checkpoint/gate_out`, {
      workorder: woid,
    })
    return response
  }
}

export interface IGateConfig {
  gate: {
    created: {
      timestamp: string
      by: {
        id: string
        resolved: boolean
      }
    }
    id: string
    name: string
    owner: {
      resolved: boolean
      id: string
      ref: string
      name: string
    }
    security_config: {
      area: string
      port: {
        id: string
        name: string
        ref: string
        resolved: boolean
      }
    }
    timezone: string
  }
  kiosk: null
  gate_in: null | {
    checkpoint: {
      allow: boolean
      id: string
      requiresVehicle: boolean
    }
    open: {
      driver: boolean
      label: any
    }
  }
  gate_out: null | {
    checkpoint: {
      allow: boolean
      id: string
      requiresVehicle: boolean
    }
    open: {
      driver: boolean
      label: any
    }
  }
}
