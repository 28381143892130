import { AxiosInstance } from '@griegconnect/krakentools-react-kraken-app'
import { HaulierId } from '../dtos/terminalHaulierAgreement'
import { PreAdviceType } from '../types/PreAdvice'
import { Page } from '../../apis/Page'
import { IAvailableDto } from '../dtos/availableDto'
import { IIdentifiedContainerDto } from '../dtos/containerDto'

const avilableIdentifiedToPreAdviceWizardTypeContainer = (
  container: IIdentifiedContainerDto,
  direction: PreAdviceType.Direction,
  booking?: PreAdviceType.WizardBooking
): PreAdviceType.WizardItem => {
  const metadata: PreAdviceType.WizardItemContainerMetadata | undefined = container.metadata
    ? {
        booking: container.metadata.booking ?? undefined,
        cargoweight: container.metadata.cargoweight ?? undefined,
        carrierseal: container.metadata.carrierseal ?? undefined,
        commodity: container.metadata.commodity?.id ?? undefined,
        imo: container.metadata.imo?.id ?? undefined,
        vgm: container.metadata.vgm ?? undefined,
      }
    : undefined

  return {
    type: 'container',
    direction,
    container: {
      id: container.id,
      number: container.number,
      booking,
      metadata,
      owner: container.owners[0],
      preregistered: container.preregistered,
      returningtoterminal: container.returningtoterminal,
      size: container.sizes[0],
      type: container.types[0],
    },
  }
}

export class HaulierPreAdviceApi {
  private httpClient: AxiosInstance
  haulierid: HaulierId

  constructor(httpClient: any, apiUrl: string, haulierId: HaulierId, useAuth: boolean = true) {
    this.httpClient = httpClient(apiUrl, useAuth)
    this.haulierid = haulierId
  }

  async searchReference(terminalRef: string, query: string): Promise<PreAdviceType.WizardItem[]> {
    const wizardItems: PreAdviceType.WizardItem[] = []
    const response = await this.httpClient.get<IAvailableDto>(
      `/hauliers/${this.haulierid}/terminals/${terminalRef}/available?q=${query}`
    )

    if (response.data.type === 'consignee') {
      throw new Error('Consignee not suported')
    } else {
      const container_booking = response.data.type === 'booking' ? { ref: response.data.ref } : undefined

      response.data.delivery
        .filter((item) =>
          // If container only has a 'string ref' aka not connected to a booking in booking module we should not make it available to user
          response.data.type !== 'booking'
            ? true
            : item.metadata && item.metadata.booking && item.metadata.booking.id
            ? true
            : false
        )
        .forEach((item) =>
          wizardItems.push(
            avilableIdentifiedToPreAdviceWizardTypeContainer(
              item,
              'in',
              container_booking ? { ...container_booking, id: item.metadata!.booking!.id } : undefined
            )
          )
        )
      response.data.pickup.identified.forEach((item) =>
        wizardItems.push(
          avilableIdentifiedToPreAdviceWizardTypeContainer(
            item,
            'out',
            container_booking ? { ...container_booking, id: item.metadata!.booking!.id } : undefined
          )
        )
      )
      response.data.pickup.unidentified.forEach((item) =>
        wizardItems.push({
          type: 'booking',
          direction: 'out',
          booking: {
            id: item.id,
            ref: response.data.ref,
            owners: item.owners,
            remaining: item.remaining,
            total: item.total,
            sizes: item.sizes,
            types: item.types,
            available: item.available
          },
        })
      )
      return wizardItems
    }
  }

  async list() {
    const preadvices = await this.httpClient.get<Page<PreAdviceType.Data>>(`/hauliers/${this.haulierid}/preadvices`)
    return preadvices.data.values
  }

  async listByTerminal(terminalid: string) {
    const preadvices = await this.httpClient.get<Page<PreAdviceType.Data>>(
      `/hauliers/${this.haulierid}/preadvices?terminal=${terminalid}`
    )
    return preadvices.data.values
  }

  async create(form: PreAdviceType.Form) {
    const response = await this.httpClient.post<string>(
      `/hauliers/${this.haulierid}/preadvices`,
      form
    )
    return response.data
  }

  async update(form: PreAdviceType.Form) {
    const response = await this.httpClient.put<any, any, PreAdviceType.Form>(
      `/hauliers/${this.haulierid}/preadvices/${form.id}`,
      form
    )

    return response.data
  }

  async delete(id: string) {
    const response = await this.httpClient.delete(`/hauliers/${this.haulierid}/preadvices/${id}`)

    return response.data
  }

  async itemCount(terminalId: string, bookingRef: string) {
    const itemCount = await this.httpClient.get<number>(
      `/hauliers/${this.haulierid}/preadvices/items?terminal=${terminalId}&booking=${bookingRef}`
    )
    return itemCount.data
  }
}
