import { LicenseInfo } from '@mui/x-license-pro'
import { createRoot } from 'react-dom/client'
import { App } from './App'
import './config/i18n'
import HaulierProvider from './HaulierProvider'

LicenseInfo.setLicenseKey(
  '3c92ac2a13f79038b2fca55b538af70bTz03MzQ0MyxFPTE3MjU3NTAwMDAwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI='
)

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
  <HaulierProvider>
    <App />
  </HaulierProvider>
)
