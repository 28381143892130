import * as React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgBookingReference = (props: SvgIconProps) =>
  React.createElement(
    SvgIcon,
    props,
    <path d="M6 10h5V8H6v2ZM6 14h3v-2H6v2ZM12 8h6v2h-6V8ZM13 12h-3v2h3v-2Z" />,
    <path
      fillRule="evenodd"
      d="M16 20H4c-1.1 0-2-.9-2-2V6c0-1.1.89-2 1.99-2H20c1.1 0 2 .9 2 2v8l-6 6Zm4-14H4v12h11v-5h5V6Z"
      clipRule="evenodd"
    />
  )
export default SvgBookingReference
