import * as React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgVehicleReachLoader = (props: SvgIconProps) =>
  React.createElement(
    SvgIcon,
    props,
    <path d="M19 6h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1ZM20 19a2 2 0 1 1-4 0 2 2 0 0 1 4 0ZM8 19a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z" />,
    <path d="M2 15v3a1 1 0 0 0 1 1c0-.889.386-1.687 1-2.236v-1.35l2.5-2.5L10.586 17h-2.35c.475.53.764 1.232.764 2h6c0-.768.289-1.47.764-2h-2.35l-5.5-5.5 6.5-6.5H21a1 1 0 1 0 0-2h-7a1 1 0 0 0-.707.293l-11 11A1 1 0 0 0 2 15Z" />,
    <path d="M16.187 16.61a2.987 2.987 0 0 1 1.932-.608l-2.225-4.45A1 1 0 0 0 15 11h-3a1 1 0 0 0-1 1v1l2 2v-2h1.382l1.805 3.61Z" />
  )
export default SvgVehicleReachLoader
