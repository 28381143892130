import * as React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
const SvgInformation = (props: SvgIconProps) =>
  React.createElement(
    SvgIcon,
    props,
    <path d="M13 7v2h-2V7h2ZM13 11v5h1v1h-4v-1h1v-4h-1v-1h3Z" />,
    <path
      fillRule="evenodd"
      d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2Zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8Z"
      clipRule="evenodd"
    />
  )
export default SvgInformation
